<template>
	<div>
		<div class="px-3">
			<div class="p-sticky bg-white">
				<v-layout class="align-center light-blue-bg px-2 flex-wrap">
					<v-col cols="12">
						<v-row class="mt-2">
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Server Name : </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span
											v-if="getValue(detail_data, 'name')"
											class="mb-0 fw-600 text-capitalize"
											style="font-size: 13px"
										>
											{{ getValue(detail_data, "name") }}
										</span>
										<em class="text-muted" v-else>no server name</em>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Activation Date: </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span
											v-if="getValue(detail_data, 'activation_date')"
											class="mb-0 fw-600"
											style="font-size: 13px"
										>
											<v-icon size="22">mdi-calendar</v-icon>
											{{ formatDate(getValue(detail_data, "activation_date")) }}
										</span>
										<em class="text-muted" v-else>no activation date</em>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">RAM: </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span v-if="getValue(detail_data, 'Ram')" class="mb-0 fw-600" style="font-size: 13px">
											<v-icon size="22">mdi-cpu-64-bit</v-icon>
											{{ getValue(detail_data, "Ram") }}&nbsp;GB
										</span>
										<em class="text-muted" v-else>no ram</em>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Cores: </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span
											v-if="getValue(detail_data, 'no_of_cores')"
											class="mb-0 fw-600"
											style="font-size: 13px"
										>
											{{ getValue(detail_data, "no_of_cores") }}
										</span>
										<em class="text-muted" v-else>no cores</em>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Service Provider: </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<v-chip
											v-if="getValue(detail_data, 'cost')"
											small
											label
											color="white--text green darken-4"
										>
											<span class="mb-0 fw-500" style="font-size: 13px">
												{{ getValue(detail_data, "service_provider") }}
											</span>
										</v-chip>
										<em class="text-muted" v-else>no type</em>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Renewal Date: </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span
											v-if="getValue(detail_data, 'renewal_date')"
											class="mb-0 fw-600"
											style="font-size: 13px"
										>
											<v-icon size="22">mdi-calendar</v-icon>
											{{ formatDate(getValue(detail_data, "renewal_date")) }}
										</span>
										<em class="text-muted" v-else>no renewal date</em>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Storage: </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span
											v-if="getValue(detail_data, 'storage')"
											class="mb-0 fw-600 text-capitalize"
											style="font-size: 13px"
										>
											<v-icon size="22">mdi-sd</v-icon>
											{{ getValue(detail_data, "storage") }}
										</span>
										<em class="text-muted" v-else>no storage</em>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Database: </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span
											v-if="getValue(detail_data, 'no_of_database')"
											class="mb-0 fw-600 text-capitalize"
											style="font-size: 13px"
										>
											<v-icon size="22">mdi-database</v-icon>
											{{ getValue(detail_data, "no_of_database") }}
										</span>
										<em class="text-muted" v-else>no database</em>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Type : </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span
											v-if="getValue(detail_data, 'provider_type')"
											class="mb-0 fw-600 text-capitalize"
											style="font-size: 13px"
										>
											<v-icon size="22">{{
												getValue(detail_data, "provider_type") == "company" ? "mdi-domain" : "mdi-account"
											}}</v-icon>
											{{ getValue(detail_data, "provider_type") }}
										</span>
										<em class="text-muted" v-else>no type</em>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Renewal Basis : </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span
											v-if="getValue(detail_data, 'cost')"
											class="mb-0 fw-600 text-capitalize"
											style="font-size: 13px"
										>
											<v-icon size="22">mdi-update</v-icon>
											{{ getValue(detail_data, "renewal_basis") }}
										</span>
										<em class="text-muted" v-else>no cost</em>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="3">
								<v-row>
									<v-col cols="4" style="padding: 0px !important">
										<span class="fw-600">Cost : </span>
									</v-col>
									<v-col cols="8" style="padding: 0px !important">
										<span
											v-if="getValue(detail_data, 'cost')"
											class="mb-0 ms-2 fw-600"
											style="font-size: 13px"
											>{{ formatMoney(detail_data?.cost) }}</span
										>
										<em class="text-muted" v-else>no cost</em>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-layout>

				<v-layout class="pa-2 pb-0">
					<v-tabs
						v-model="serverTab"
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent w-100"
						active-class="blue darken-4 text-white"
						hide-slider
					>
						<v-tab href="#renew"><v-icon small left>mdi-information-outline</v-icon>Renew History</v-tab>
						<v-tab href="#activity_log"
							><v-icon small left>mdi-google-analytics</v-icon>Activity Log</v-tab
						>
					</v-tabs>
				</v-layout>
			</div>
			<div class="tab-layout-content project-tb-content px-4">
				<v-tabs-items v-model="serverTab">
					<v-tab-item v-if="serverTab == 'renew'" value="renew" class="">
						<div class="mt-2 v-data-table bt-table theme--light">
							<div class="v-data-table__wrapper">
								<table class="w-100">
									<thead>
										<tr>
											<th width="150px">Renew Date</th>
											<th width="150px">Payment Date</th>
											<th width="120px">Cost</th>
											<th width="250px">Remark</th>
											<th width="150px">Added By</th>
											<th width="150px">Added At</th>
										</tr>
									</thead>
									<tbody>
										<template v-if="renew_history && renew_history.length > 0">
											<tr v-for="(td, td_idx) in renew_history" :key="'server_' + td.id + '_' + td_idx">
												<!-- <template v-for="(th, th_idx) in sortedThead"> -->
												<!-- :width="th.width" -->
												<td>
													<template v-if="td.renew_date">
														<v-icon size="19" class="mr-1" left>mdi-calendar</v-icon>
														{{ formatDate(td.renew_date) }}
													</template>
													<em v-else class="text-muted">no renew date</em>
												</td>

												<td>
													<template v-if="td.payment_date">
														<v-icon size="19" class="mr-1" left>mdi-calendar</v-icon>
														{{ formatDate(td.payment_date) }}
													</template>
													<em v-else class="text-muted">no payment date</em>
												</td>

												<td>
													<template v-if="td.cost">{{ formatMoney(td.cost) }}</template>
													<em v-else class="text-muted">no cost</em>
												</td>

												<td>
													<template v-if="td.remark">
														<v-tooltip top>
															<template #activator="{ on, attrs }">
																<div class="text-truncate" v-on="on" v-bind="attrs" style="max-width: 250px">
																	{{ td.remark }}
																</div>
															</template>
															<span>{{ td.remark }}</span>
														</v-tooltip>
													</template>
													<em v-else class="text-muted">no remark</em>
												</td>

												<td>
													<template v-if="getValue(td, 'added_by.display_name')">
														<v-chip>
															<v-avatar left>
																<img
																	v-if="getValue(td, 'added_by.profile_img')"
																	:src="getValue(td, 'added_by.profile_img')"
																	:alt="getValue(td, 'added_by.display_name')"
																/>
																<img
																	v-else
																	src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																	alt="no-user"
																/>
															</v-avatar>
															<span class="text-capitalize">
																{{ getValue(td, "added_by.display_name") }}
															</span>
														</v-chip>
													</template>
													<em v-else class="text-muted">no added by</em>
												</td>

												<td>
													<template v-if="td.added_at">{{ formatDate(td.added_at) }}</template>
													<em v-else class="text-muted">no added at</em>
												</td>
												<!-- </template> -->
											</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item v-if="serverTab == 'activity_log'" value="activity_log">
						<div
							class="px-2"
							v-if="activity_log && activity_log.length"
							style="height: calc(100vh - 275px); overflow-y: auto"
						>
							<div
								v-for="(activity, index) in activity_log"
								:key="activity.domain + index"
								class="d-flex flex-column"
								:class="[{ 'mt-3': index == 0 }]"
							>
								<v-timeline dense>
									<v-timeline-item>
										<div class="tab-card d-flex justify-content-between pa-3 mb-3 border tab-custom-data">
											<div>
												<div class="d-flex mb-2 fw-500">
													<span class="fw-600 mr-1">Server</span>
													Activity
												</div>
												<div class="mb-2 fw-500">
													<span class="fw-600 primary--text mr-2" v-if="getValue(activity, 'server.name')">
														{{ getValue(activity, "server.name") }}&nbsp; (<span class="text-capitalize">{{
															getValue(activity, "server.provider_type")
														}}</span
														>)
													</span>

													<div class="mt-1">
														<span class="primary--text" v-if="getValue(activity, 'server.service_provider')">
															{{ getValue(activity, "server.service_provider") }}
														</span>
													</div>

													<div class="mt-2">
														<p v-if="getValue(activity, 'description')">
															{{ getValue(activity, "description") }}
														</p>
													</div>
												</div>
											</div>
											<div style="width: 280px" class="date align-items-center">
												<div class="mb-2 fw-500 d-flex justify-content-between">
													<p>Date :</p>
													<p class="primary--text mr-2" v-if="getValue(activity, 'added_at')">
														<v-icon left color="primary" class="mr-0" size="19">mdi-calendar-clock</v-icon>
														{{ formatDateTime(getValue(activity, "added_at")) }}
													</p>
													<p class="primary--text mr-2" v-else>
														<em class="text-muted">no activation date</em>
													</p>
												</div>
												<!-- <div class="mb-2 fw-500 d-flex justify-content-between">
											<p>Renewal Date :</p>
											<p class="primary--text mr-2" v-if="getValue(activity, 'server.renewal_date')">
												{{ formatDate(getValue(activity, "server.renewal_date")) }}
												<span class="text-capitalize">({{ getValue(activity, "server.renewal_basis") }})</span>
											</p>
											<p class="primary--text mr-2" v-else>
												<em class="text-muted">no renewal date</em>
											</p>
										</div> -->
												<div class="mb-2 fw-500 d-flex justify-content-between">
													<p>IP Address :</p>
													<p class="primary--text mr-2" v-if="getValue(activity, 'ip')">
														{{ getValue(activity, "ip") }}
													</p>
													<p class="primary--text mr-2" v-else>
														<em class="text-muted">no ip address</em>
													</p>
												</div>
											</div>
										</div></v-timeline-item
									>
								</v-timeline>
							</div>
						</div>
						<template v-else>
							<p class="m-0 row-not-found text-center">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no activity at the moment.
							</p>
						</template>
					</v-tab-item>
				</v-tabs-items>
			</div>

			<RenewServer
				v-if="renewDialog"
				:server-dialog="renewDialog"
				@close="$emit('close')"
				@success="successRenew"
				:server-id="serverId"
			/>
		</div>
	</div>
</template>
<script>
import { GET, QUERY } from "@/core/services/store/request.module";
import objectPath from "object-path";
import HeightMixin from "@/core/mixins/height.mixin";
import { mapGetters } from "vuex";
import { SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
import { SET_SEARCH_BAR } from "@/core/services/store/listing.module";
import RenewServer from "@/view/components/RenewServer.vue";

export default {
	name: "ServerSettings",
	mixins: [HeightMixin],
	components: {
		RenewServer,
	},
	props: {
		renewDialog: {
			type: Boolean,
			default: false,
		},
		refress: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			skipBottomHeight: 57,
			page: 1,
			status: null,
			addServerDialog: false,
			endpoint: "server-detail",
			serverId: null,
			isDetail: false,
			detail_data: null,
			activity_log: [],
			renew_history: [],
		};
	},
	watch: {
		/* "$route.query": {
			deep: true,
			handler(route) {
				if (objectPath.get(route, "tab") == "server") {
					if (!objectPath.has(route, "detailId")) {
						this.isDetail = false;
						this.getListing();
					} else {
						let id = objectPath.get(route, "detailId");
						id && this.getServerById(id);
					}
				}
			},
		}, */
		refress: {
			handler() {
				this.successRenew();
			},
		},
	},
	methods: {
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		goBack() {
			this.$router.go(-1);
		},
		successRenew() {
			let query = this.$route.query;
			let detail_id = objectPath.get(query, "detailId");
			this.getServerActivityLog(detail_id);
			this.getServerRenewHistory(detail_id);
			this.$emit("refress", false);
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.filterRows();
			});
		},
		routeToDetail(td, th) {
			if (th.checkbox || th.key == "action") {
				return false;
			}
			this.$router.push({
				path: "setting",
				query: { tab: "server", detailId: td.id },
				// params: { id: td.id },
			});
			this.isDetail = true;
		},
		doAction(type, td) {
			switch (type) {
				case "update":
					this.serverId = td.id;
					this.addServerDialog = true;
					break;
			}
		},
		getServerById(id) {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `server/${id}`,
				})
				.then((data) => {
					this.detail_data = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getServerActivityLog(id) {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(QUERY, {
					url: `activity-log`,
					data: { id, module: "Server" },
				})
				.then((data) => {
					if (data && data.length) {
						this.activity_log = data;
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getServerRenewHistory(id) {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `server-renew/${id}`,
				})
				.then((data) => {
					if (data) {
						this.renew_history = data;
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
		serverTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.subTab = val;
				if (val != this.serverTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.subTab || "renew";
			},
		},
	},
	mounted() {
		this.$store.dispatch(SET_SEARCH_BAR, true);
		let query = this.$route.query;
		if (objectPath.has(query, "detailId") && objectPath.get(query, "tab") == "server") {
			let detail_id = objectPath.get(query, "detailId");
			this.isDetail = true;
			this.serverId = detail_id;
			this.getServerById(detail_id);
			this.getServerActivityLog(detail_id);
			this.getServerRenewHistory(detail_id);
		}
	},
};
</script>
